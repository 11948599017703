<template>
  <dash-page-new
      :title="$t('Contacts')"
      :filters="filters || []"

      :filters-input="filtersData || {}"
      @filter="filtersData = $event"
  >

<!--    AJAX Search for Pagination-->
<!--    :filters-search-ajax="$store.state.settings.pageData.filtersSearchAjax || null"-->
<!--    :filters-search="$store.state.settings.pageData.filtersSearch || false"-->

    <template #header_action>
      <ft-select :items="addContactSelect">
        <ws-button
            :click-stop="false"
            label="contacts.add"
            left-icon="mdi-plus"
        />
      </ft-select>

    </template>

    <template #header.after>
      <ws-navigation-header

          :items="navigationSelect"
          v-model="navigation"
          right
          no-number
      />
    </template>

    <template #default>

      <div class="d-flex flex-row py-5" style="height: 100%">

        <!-- Lists table-->
        <v-sheet class="overflow-hidden overflow-y-auto mr-4" style="border: 1px solid var(--wsBACKGROUND); border-radius : 4px; transition: width 0.3s ease"
                 :style="`width : ${collapseLists ? '47' : '300'}px`"

        >

          <v-sheet class="d-flex align-center justify-space-between px-2" :color="wsLIGHTCARD" height="47">
            <div v-if="!collapseLists" class="d-flex align-center">
              <v-btn @click="openAddList" class="mr-3" icon>
                <v-icon :color="wsACCENT" >mdi-folder-plus-outline</v-icon>
              </v-btn>
              <h5 class="wsACCENT">{{ $t('Lists') }}</h5>
            </div>
            <v-btn @click="handleCollapse" icon>
              <v-icon :color="wsACCENT" >mdi-arrow-collapse-{{ collapseLists ? 'right' : 'left' }}</v-icon>
            </v-btn>

          </v-sheet>


          <ws-data-table
              v-if="!collapseLists"
              :items="listsFiltered"
              :headers="liststHeaders"
              :selected-row="selectedList"
              no-footer
              no-header
              disable-pagination
              style="border-top: 1px solid var(--wsBACKGROUND);border-bottom: 1px solid var(--wsBACKGROUND);"
              :row-action="(item) => { selectedList = item.uuid}"
          >

            <template #item.name="{item,hover}">

              <div class="d-flex align-center justify-space-between">
                <h5 class="wsACCENT shorten-text" style="min-width: 200px; font-size: 12px">{{ item.name }}</h5>
                <h5 v-if="!hover || !item.uuid || item.uuid === 'no_list' " class="wsDARKER">{{ item.contacts_count }}</h5>
                <v-btn v-if="hover && !!item.uuid &&  item.uuid !== 'no_list'" @click="openEditList(item)" class="mr-n2" small  icon>
                  <v-icon size="18" :color="wsACCENT">mdi-pencil</v-icon>
                </v-btn>
              </div>

            </template>


          </ws-data-table>
        </v-sheet>

        <!-- Contacts table-->
        <ws-data-table
            style="border : 1px solid var(--wsBACKGROUND);"
            class="flex-grow-1 wsRoundedLight"
            :items="itemsFiltered"
            :headers="headers"
            :search="filtersData.search"
            :row-action="openContact"
            selectable
            :selected-rows="selectedContacts"
            @select="selectedContacts = $event.items"
            :multiple-actions="multipleActionsSelect"
        >

          <template #item.name="{item}">
            <div class="d-flex align-center " >
              <v-icon  :color="getItemColor(item)">mdi-circle-medium</v-icon>
              <h5 class="wsDARKER text-no-wrap mr-2" style="font-size: 12px; ">{{ item.name }} {{ item.lastname }}</h5>
            </div>

          </template>

          <template #item.tags="{item,hover}">
            <contact-tags-table-item
                @add-tag="tagsSelect.push($event)"
                :item="item"
                :tags-select="tagsSelect"
                :hover="hover"
            />
          </template>

          <template #item.status="{item}">
            <ws-chip :color="getStatusColor(item.status)" :text="getStatusText(item.status)" outlined />
          </template>


          <template #item.action="{item}">

            <ft-select
                @input="editAction($event,item)"
                :items="actionsSelect"
            >
              <v-btn icon>
                <v-icon :color="wsACCENT">mdi-dots-horizontal</v-icon>
              </v-btn>
            </ft-select>

          </template>


        </ws-data-table>

      </div>


    </template>

    <template #dialog>

      <!-- Display Import Users from file -->
      <ws-file-import-dialog
          v-if="displayImportDialog"
          @success="displayImportDialog = false; initPage()"
          v-model="displayImportDialog"
          entity="contacts"
      />

      <!-- Contact Dialog -->
      <ws-dialog
          v-if="displayDialog"
          v-model="displayDialog"
          @save="addEditContact"
          :title="$t(`contacts.${newContact ? 'add' : 'edit'}`)"
          :save-text="$t(newContact ? 'Add' : 'Save')"
          fullscreen
          not-centered
          fullscreen-align="right"
          fullscreen-width="380"
      >
        <div v-if="!newContact && entityData.user_id" class="d-flex  mb-5">
          <v-icon class="mr-3" :color="wsATTENTION">mdi-information</v-icon>
          <h5 class="wsACCENT font-weight-regular" >
            При зміні емейла чи телефона у користувач зміниться логін
          </h5>
        </div>

        <ws-text-field
            v-model="entityData.name"
            :label="$t('HumanName')"
            :placeholder="$t('EnterHumanName')"
            avalon-style
        />

        <ws-text-field
            class="mt-5"
            v-model="entityData.lastname"
            :label="$t('Lastname')"
            :placeholder="$t('EnterLastname')"
            avalon-style
        />

        <ws-text-field
            v-model="entityData.email"
            :label="$t('Email')"
            :placeholder="$t('EnterName')"
            class="mt-5"
            avalon-style
        />
        <ws-phone-input
            v-model="entityData.phone"
            :label="$t('Phone')"
            :placeholder="$t('EnterName')"
            class="mt-5"
            avalon-style
            hide-details
        />

      </ws-dialog>
      <!-- List Dialog -->
      <ws-dialog
          v-if="displayListDialog"
          v-model="displayListDialog"
          @save="addEditList"
          :title="$t(`contacts.lists.${newList ? 'add' : 'edit'}`)"
          :save-text="newList ? $t('Add') : $t('Save')"
          :show-delete="!newList && listEntityData.uuid !== 'no_list'"
          @delete="deleteList"
      >
        <ws-text-field
            v-model="listEntityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

      </ws-dialog>

      <!-- Move to List Dialog -->
      <ws-dialog
          v-if="displayMoveToListDialog"
          v-model="displayMoveToListDialog"
          @save="moveToList"
          :title="$t(`contacts.lists.${removeFromList ? 'remove_from' : 'add_to'}`)"
          :save-text="$t('Save')"
      >
        <ws-select
            v-if="!removeFromList || (removeFromList && selectedList === 'all')"
            v-model="moveToListEntityData.contact_list_id"
            :items="listsSelect"
            :label="$t('List')"
            :placeholder="$t('contacts.lists.select')"
            autocomplete
            clearable
        />

        <div v-if="removeFromList && selectedList !=='all'">
          <h4 class="font-weight-regular">{{ $t('contacts.lists.remove_from_confirm') }}</h4>
        </div>

      </ws-dialog>
      <!-- Tags Dialog -->
      <ws-dialog
          v-if="displayTagDialog"
          v-model="displayTagDialog"
          @save="setTag"
          :title="$t(`contacts.tags.${tagEntityData.remove ? 'remove_multiple' : 'add_multiple'}`)"
          :save-text="$t('Save')"
      >
        <ws-select
            v-model="tagEntityData.tag_id"
            :items="tagsSelect"
            :label="$t('Tag')"
            :placeholder="$t('contacts.tags.select')"
            :allow-new-data="!tagEntityData.remove"
            autocomplete
            new-data-text="AddTag"
            clearable
            :multiple="tagEntityData.remove"
        />

        <ws-system-color-select
            v-if="tagEntityData.tag_id && tagEntityData.tag_id.includes('new-data')"
            v-model="tagEntityData.color"
            label="ChooseTagColor"
            class="mt-5"
        />

      </ws-dialog>

    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import wsFileImportDialog from "@/components/UI/wsFileImportDialog.vue";
import contactTagsTableItem from "@modules/contacts/components/UI/contactTagsTableItem.vue";

export default {
  name: "mailerСontacts",
  components: {
    wsFileImportDialog,
    contactTagsTableItem
  },
  data() {
    return {
      displayDialog : false,
      selectedItem : {},
      entityData : {},
      items : [],
      lists : [],
      filtersData : {},
      navigation : 'contacts',
      liststHeaders : [{value : 'name'}],
      displayListDialog : false,
      listEntityData : {},
      collapseLists : false,
      newList : false,
      newContact : false,
      selectedList : 'all',
      selectedContacts : [],
      displayMoveToListDialog : false,
      displayTagDialog : false,
      moveToListEntityData : {},
      tagEntityData : {},
      tagsSelect : [],
      removeFromList : false,
      displayImportDialog : false
    }
  },
  computed : {

    addContactSelect() {
      return [
        { text: this.$t('contacts.create'),
          action: this.openNewContact,
          icon : 'mdi-plus'
        },
        { text: this.$t('contacts.import.title'),
          action: this.openImportDialog ,
          icon : 'mdi-table-arrow-left'
        }
      ]
    },
    tagsNames() {
      let object = {}
      this.tagsSelect.forEach(item => {
        object[item.value] = item.text
      })

      return object
    },
    filters() {
      return [
        { text : this.$t('Role')  , value : 'status' , type : 'select' , items : [
            { text : 'Student' , value : 'student' },
            { text : 'Manager' , value : 'manager' },
            { text : 'Lead' , value : 'lead' }
          ] },
        { text : this.$t('Course_')  , value : 'course_list' , type : 'select' , items : this.coursesListSelect },
      ]
    },
    listsSelect() {
      let items = this.COPY(this.lists)
      items = items.filter(el => el.uuid !== 'no_list').map(el => ({text : el.name, value : el.uuid}))
      return items
    },
    coursesListSelect() {
      let items = this.COPY(this.lists)
      items = items.filter(el => el.is_system && el.system_type === 'course').map(el => ({text : el.name, value : el.uuid}))
      return items
    },
    listsFiltered() {
      let items = this.COPY(this.lists)
      items = items.filter(el => !el.is_system)
      items.unshift({ name : this.$t('All') , uuid : 'all' , contacts_count : this.items.length })
      let popNoLists = false
      items.forEach(item => {
        if (item.uuid === 'no_list') {
          item.name = this.$t('contacts.lists.no_list')
          if ( !item.contacts_count ) {
            popNoLists = true
          }
        }
      })
      if (popNoLists) {
        items.pop()
      }
      return items
    },
    itemsFiltered() {
      let items = this.items

      if ( this.navigation === 'contacts') {
        items = items.filter(el => !el.bounced && !el.unsubscribed)
      } else if ( this.navigation === 'unsubscribed') {
        items = items.filter(el => el.unsubscribed)
      } else if ( this.navigation === 'bounced') {
        items = items.filter(el => el.bounced)
      }

      if ( this.filtersData.course_list) {
        items = items.filter(el => el.contact_list_ids && el.contact_list_ids.includes(this.filtersData.course_list))
      }

      if ( this.selectedList && this.selectedList !== 'all' ) {

        if ( this.selectedList !== 'no_list') {
          items = items.filter(el => el.contact_list_ids.includes(this.selectedList) )
        } else {
          items = items.filter(el => el.contact_list_ids.length === 0 )
        }

      }

      return items
    },
    headers() {
      return [
        { text : this.$t('HumanName') ,   value : 'name' ,   sortable : false},
        { text : this.$t('Email')   ,  value : 'email'   , sortable : false},
        { text : this.$t('Tags') ,   value : 'tags' ,  width : '200px', sortable : true },
        { text : this.$t('Lang') ,   value : 'lang' ,  width : '10px', sortable : false },
        { value : 'action' , width : '10px', sortable : false }
      ]
    },
    multipleActionsSelect() {
      let items =  [
        { text : 'contacts.lists.add_to' ,
          icon : 'mdi-folder-outline',
          action :  this.openMoveToList
        },
        { text : 'contacts.tags.add',
          icon : 'mdi-label-outline',
          action :  this.openSetTags
        },
        { text : 'contacts.tags.remove',
          icon : 'mdi-label-off-outline',
          action :  () => this.openSetTags(true)
        },
      ]
      if ( this.lists.length > 1 && !['all','no_list'].includes(this.selectedList) ) {
        items.push({
          text : 'contacts.lists.remove',
          icon : 'mdi-folder-minus-outline',
          action :  this.openRemoveFromList
        })
      }



      return items

    },
    navigationSelect() {
      return [
        { text : this.$t('Active') , value : 'contacts' },
        { text : this.$t('Unsubscribed') , value : 'unsubscribed' },
        { text : this.$t('Bounced') , value : 'bounced' }
      ]
    },
    actionsSelect() {
      return [
        { text : this.$t('Open') , value : 'view' },
        { text : this.$t('Edit') , value : 'edit'},
        { text : this.$t('Delete') , value : 'delete'}
      ]
    }
  },
  methods : {

    ...mapActions('contacts' , [
      'GET_CONTACTS',
      'ADD_EDIT_CONTACT',
      'ADD_EDIT_CONTACT_LIST',
      'DELETE_CONTACT_LIST',
      'MOVE_TO_CONTACT_LIST',
      'SET_CONTACTS_TAGS',
    ]),

    openRemoveTags() {
      this.notify('remove tags')
    },

    openImportDialog() {
      this.displayImportDialog = true
    },
    openRemoveFromList() {
      this.removeFromList = true
      this.displayMoveToListDialog = true
      this.moveToListEntityData = {
        contact_list_id : this.selectedList || null,
        remove : true,
      }

    },
    addTag() {

    },
    async setTag() {
      this.tagEntityData.contacts = this.selectedContacts
      let result = await this.SET_CONTACTS_TAGS(this.tagEntityData)
      if ( !result ) {
        return this.ERROR()
      }

      this.initPage()


      this.selectedContacts = []
      this.displayTagDialog = false
    },
    openSetTags(remove) {
      this.displayTagDialog = true
      this.tagEntityData = {}
      if (remove) {
        this.tagEntityData.remove = true
      }
    },

    async moveToList() {
      this.moveToListEntityData.contacts = this.selectedContacts
      let result = await this.MOVE_TO_CONTACT_LIST(this.moveToListEntityData)
      if ( !result ) {
        return this.ERROR()
      }


      this.items.forEach(item => {

        if (this.moveToListEntityData.contacts.includes(item.uuid)) {
          if ( !item.contact_list_ids.includes(this.moveToListEntityData.contact_list_id)) {
            item.contact_list_ids.push(this.moveToListEntityData.contact_list_id)
          }
          if (this.moveToListEntityData.remove && item.contact_list_ids.includes(this.moveToListEntityData.contact_list_id) ) {
            item.contact_list_ids = item.contact_list_ids.filter( el => el !==  this.moveToListEntityData.contact_list_id)
          }
        }

      })
      this.items = this.COPY(this.items)
      this.selectedContacts = []

      this.displayMoveToListDialog = false

      const index = this.lists.findIndex(el=> el.uuid === this.moveToListEntityData.contact_list_id)
      if (index === -1) {
        return
      }

      if (!this.moveToListEntityData.remove) {
        this.lists[index].contacts_count += this.moveToListEntityData.contacts.length
      } else {
        this.lists[index].contacts_count -= this.moveToListEntityData.contacts.length
        if ( this.lists[index].contacts_count < 0 ) {
          this.lists[index].contacts_count = 0
        }
      }


      if ( this.selectedList === 'no_list') {
        this.lists[this.lists.length - 1].contacts_count -= this.moveToListEntityData.contacts.length
      }
      this.lists = this.COPY(this.lists)

      this.selectedList = this.moveToListEntityData.contact_list_id

      let successText = `${this.$t('contacts.lists.moved')}: ${this.list[index].name}`
      this.notify(successText , 'success')
    },
    openMoveToList() {
      this.removeFromList = false
      this.displayMoveToListDialog = true
      this.moveToListEntityData = {}
    },
    async deleteList() {
      let result = await this.DELETE_CONTACT_LIST(this.listEntityData.uuid)
      if ( !result) {
        return this.ERROR()
      }
      const index =  this.lists.findIndex(el => el.uuid === this.listEntityData.uuid)
      if (index === -1) {
        return
      }
      this.lists.splice(index, 1)
      this.displayListDialog = false

    },
    async addEditList() {
      let result = await this.ADD_EDIT_CONTACT_LIST(this.listEntityData)
      if (!result) {
        return this.ERROR()
      }
      if ( this.newList) {
        result.contacts_count = 0
        this.lists.push(result)
      } else {
        let index = this.lists.findIndex(el => el.uuid === result.uuid)
        if (index === -1) {
          return
        }
        this.lists[index] = result
        this.lists = this.COPY(this.lists)
      }

      this.displayListDialog = false
    },
    openEditList(item) {
      this.newList = false
      this.listEntityData = this.COPY(item)
      this.displayListDialog = true
    },
    openAddList() {
      this.newList = true
      this.listEntityData = {}
      this.displayListDialog = true
    },
    handleCollapse() {
      this.collapseLists = !this.collapseLists
    },

    async addEditContact() {
      let result = await this.ADD_EDIT_CONTACT(this.entityData)
      if (!result) {
        return this.ERROR()
      }
      if (this.newContact) {
        this.items.push(result)
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid)
        if (index === -1) {
          return
        }
        this.items[index] = result
        this.items = [...this.items]
      }

      this.navigation = 'contacts'
      this.displayDialog = false
    },

    openContact(item) {
      this.newContact = false
      this.entityData = this.COPY(item)
      this.displayDialog = true
    },
    openNewContact() {
      this.newContact = true
      this.entityData = {}
      this.displayDialog = true
    },

    editAction(action , item) {
      this.notify(item)
    },
    // Technical

    getItemColor(item) {
      if ( item.bounced) {
        return this.wsWARNING
      }
      return item.is_verified_email ? this.wsSUCCESS : this.wsATTENTION
    },

    async initPage() {
      let result = await this.GET_CONTACTS()

      if (!result && result !== true) {
        return this.ERROR()
      }
      this.items = result.items || []
      this.lists = result.lists || []
      this.tagsSelect = result.tags || []
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>